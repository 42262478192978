<div class="footer-container"(window:resize)="onResize($event)">
  <div class="main-content">
     <div class="footer_help">
      <p>Ayuda</p>
      <a href="https://www.confia.com.es/contactanos">Atención al cliente</a>
      <a routerLink="/faqs">FAQs</a>
     </div>
      <div class="footer_legal">
        <p>Información legal</p>
        <a href="https://www.confia.com.es/condiciones-generales">Condiciones generales</a>
          <a href="https://www.confia.com.es/politica-privacidad">Política de privacidad</a>
      </div>
      <div class="footer_contact">
        <p>Contacto</p>
        <a href="mailto:info@confia.com.es">info@confia.com.es</a>
      </div>
  </div>
  <div class="footer_social">
    <div>
      <a href="https://www.twitter.es/"><mat-icon class="white-color" svgIcon="twitter"></mat-icon></a>
      <a href="https://www.facebook.es/"><mat-icon class="white-color" svgIcon="facebook"></mat-icon></a>
      <a href="https://www.linkedin.es/"><mat-icon class="white-color" svgIcon="linkedin"></mat-icon></a>
    </div>
  </div>
</div>