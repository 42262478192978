import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerComponent } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {
 

  constructor(private dialog: MatDialog) {  
  
  }

  start(message?: string): MatDialogRef<SpinnerComponent> {  
    const dialogRef = this.dialog.open(SpinnerComponent,{  
        disableClose: true ,  
        data: message == ''|| message == undefined ? "Cargando..." : message  
    });  
    return dialogRef;  
  };  

  message(message: string, ref?:MatDialogRef<SpinnerComponent>){  
    ref?.componentInstance.sendMessage(message)
  }  

  stop(ref?:MatDialogRef<SpinnerComponent>){  
    ref?.close();  
  }    

}
