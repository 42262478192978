<mat-dialog-content>
    <div>
        <img src="../../../../assets/img/logo azul.png" alt="logo welcome" color="primary">
        <h3>¿Seguro que quieres salir?</h3>
        <button class="button-form" (click)="logout()">
            Salir
        </button>
    </div>
</mat-dialog-content>
<!-- <mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="true" color="primary" class="button-form btn_login" (click)="logout()">
        Salir
    </button>
</mat-dialog-actions> -->


