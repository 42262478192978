
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { LoginRequest, LoginResponse } from '../../models';
import { LoginAbstractService } from './login.abstract-service';
@Injectable({
  providedIn: 'root'
})
export class LoginMockService implements LoginAbstractService {

  public login(req: LoginRequest): Observable<LoginResponse> {
    const response = new LoginResponse()
    response.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
    return of(response);
  }

  public logOut(): Observable<void> {
    return of()
  }
}
