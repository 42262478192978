<mat-dialog-content>
    
    <!-- WELCOME -->
    <div *ngIf="page=='welcome'" class="welcome_container">
        <img src="../../../../assets/img/candado welcome.png" alt="logo welcome" color="primary">
        <h3>Por razones de seguridad esta sesión unicamente te permite acceder a esta compra.</h3>
        <p>Si deseas ver el resto de detalles de tu cuenta deberás entrar con tu DNI.</p>
        <!-- ACTION BUTTON -->
        <div class="buttons">
        <button (click)="welcomeAction()" class="button-form">
        Continuar
        </button>
        <button class="button-empty" (click)="close()">
        Volver a la compra
        </button>
    </div>
    </div>

    <!-- EMAIL -->
    <div *ngIf="page=='email'" class="welcome_container">
        <img src="../../../../assets/img/logo azul.png" alt="logo welcome" color="primary">
        <p>Te hemos enviado un email para que puedas acceder a tu cuenta.</p>
        <img src="../../../../assets/img/candado welcome.png" alt="logo welcome" color="primary">
        <p>¿No has recibido el email?</p>
        <!-- ACTION BUTTON -->
        <button mat-flat-button color="primary" (click)="sendEmailAction()">
        Volver a intentar
        </button>
        <mat-progress-bar *ngIf="progress" mode="indeterminate"></mat-progress-bar>
    </div>    
    
    <form [formGroup]="form" (ngSubmit)="activate(page, formDirective)" #formDirective="ngForm" *ngIf="page!='welcome' && page!='email'">
        <img class="login_logo" src="../../../../assets/img/logo azul.png" alt="logo confia">
        <h3 class="login_title">Iniciar sesión</h3>

        <!-- PRIMARY -->
        <mat-form-field appearance="legacy" *ngIf="form.contains('primary')">
            <mat-label>DNI/NIE</mat-label>
            <input matInput formControlName="primary">
            <mat-error *ngIf="f['primary'].errors?.['required']">
                Por favor introduce su DNI o NIE
            </mat-error>
        </mat-form-field>

        <!-- SECONDARY -->
        <mat-form-field appearance="legacy" *ngIf="form.contains('secondary')">
            <mat-label>Email o Nº de teléfono</mat-label>
            <input matInput formControlName="secondary">
            <mat-error *ngIf="f['secondary'].errors?.['required']">
                Por favor introduce su email o número de teléfono
            </mat-error>
        </mat-form-field>

        <!-- OTP -->
        <mat-form-field appearance="legacy" *ngIf="form.contains('otp')">
            <mat-label>Introducir código de SMS</mat-label>
            <input matInput formControlName="otp">
            <mat-error *ngIf="f['otp'].errors?.['required']">
                Por favor introduce el código del SMS
            </mat-error>
        </mat-form-field>

        <!-- ERROR MESSAGE -->
        <mat-error *ngIf="error">{{ error }}</mat-error>

      <!-- ACTION BUTTON -->
        <button class="button-form" type="submit" [disabled]="!form.valid">
            Continuar
        </button>
        <mat-progress-bar *ngIf="progress" mode="indeterminate"></mat-progress-bar>
    </form>
</mat-dialog-content>