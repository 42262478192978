
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LoginRequest, LoginResponse } from '../../models';
import { LoginAbstractService } from './login.abstract-service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements LoginAbstractService {

 
   constructor(private http:HttpClient){

   }
 
   login(req:LoginRequest): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${environment.baseUrl}/login`, req)
   }

   public logOut(): Observable<void> {
    throw new Error('Method not implemented.');
  }   
}
