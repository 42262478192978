import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent {

  message: string = ''

  constructor(public dialogRef: MatDialogRef<SpinnerComponent>   
    , @Inject(MAT_DIALOG_DATA) public data: any) {  
      this.message = data
  }
  
  public sendMessage(message: string) {
    console.log("Mensaje recibido " + message)
    this.message = message
  }
}
