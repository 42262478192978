import { LogoutComponent } from './shared/layout/logout/logout.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared';
import { FaqComponent } from './modules/faq/faq.component';
import { LandingComponent } from './modules/landing/landing/landing.component';
import { HowToBuyComponent } from './modules/landing/how-to-buy/how-to-buy.component';
import { PaymentInfoComponent } from './modules/landing/payment-info/payment-info.component';
import { LandingCardsComponent } from './modules/landing/landing-cards/landing-cards.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginWrapperComponent } from './shared/layout/login/login.wrapper.component';


@NgModule({
  declarations: [
    AppComponent,
    FaqComponent,
    LandingComponent,
    HowToBuyComponent,
    PaymentInfoComponent,
    LandingCardsComponent,
    LoginWrapperComponent

  ],
  imports: [
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule
  ],
  entryComponents: [LogoutComponent],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
