<mat-toolbar color="primary" class="header_container">
  <div class="logo_img">
    <img src="../../../../assets/svg/logo.svg" alt="" (click)="homePage()">
  </div>
<button mat-button (click)="openDialog()">
  <mat-icon class="power_img">power_settings_new</mat-icon>
</button>
</mat-toolbar>


