import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from './../../../core/services/auth.service';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor( 
    private authService: AuthService, private dialogRef: MatDialogRef<LogoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data:string, 
  ) { }

  ngOnInit(): void {
  }
  
  logout() {
    this.authService.signOut()
    this.dialogRef.close()
  }
}
