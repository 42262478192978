import { Injectable } from '@angular/core';
import { } from 'crypto-js';

// https://www.javainuse.com/aesgenerator
// https://the-x.cn/en-us/cryptography/Aes.aspx

@Injectable({
  providedIn: 'root'
})
export class EncrDecrService {

  constructor() { }

  //The set method is use for encrypt the value.
  set(keys: string, ivs: string, value: string){
    var key = CryptoJS.enc.Base64.parse(keys);
    var iv = CryptoJS.enc.Base64.parse(ivs);
    var encrypted = CryptoJS.AES.encrypt(value.trim(), key,
    {
        keySize: 256 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys:string, ivs: string, value: string){
    var key = CryptoJS.enc.Base64.parse(keys);
    var iv = CryptoJS.enc.Base64.parse(ivs);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 256 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
