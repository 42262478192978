import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SideMenuService } from 'src/app/core/services/side-menu/side-menu.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private path: string = "../assets/svg";
  public mybreakpoint!: number;
  breakpoint= 768;

  constructor(private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer,
    public nav: SideMenuService) {

    this.matIconRegistry.addSvgIcon(
      "facebook",
      this.setPath(`${this.path}/facebook.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      "linkedin",
      this.setPath(`${this.path}/linkedin.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      "twitter",
      this.setPath(`${this.path}/twitter.svg`)
    );
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url); 
   }

  ngOnInit(){
    const w= window.innerWidth;
  if (w >= this.breakpoint) {
    this.mybreakpoint = 4;
  } else {
    this.mybreakpoint = 1;
  }
}
onResize(event:any) {

   const w= event.target.innerWidth;
  if (w >= this.breakpoint) {
    this.mybreakpoint = 4;
  } else {
    this.mybreakpoint = 1;
  }
}
}

