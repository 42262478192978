import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SideMenuService } from 'src/app/core/services/side-menu/side-menu.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  public menu: boolean = false;
  public isVisible: boolean = true
  _isVisibleButton!: boolean;
  @Input() isLoggedIn!: boolean;
  @Input() set isVisibleButton(value: boolean){
    this._isVisibleButton = value
    if(this._isVisibleButton){
      this.hideNav()
    }else{
      this.showNav()
    }
  }

  public linkList!: any;
  constructor( public nav: SideMenuService ) { }

  ngOnInit(): void {
    this.linkList = [
        {
          title: "Mi espacio",
          url: "/dashboard",
          matIcon: "pending",
        },
        {
          title: "Compras",
          url: "/shopping",
          matIcon: "paid",
        },
        {
          title: "Mi perfil",
          url: "/profile",
          matIcon: "account_circle",
        },
        {
          title: "Ayuda",
          url: "/help",
          matIcon: "help",
        },
      
    ]
  }
  
  hideNavIfMobile() {
    
    this._isVisibleButton ? this.hideNav() : null
  }
 
  showNav() {
    this.isVisible = true
  }
  hideNav() {
    this.isVisible = false
  }
}
