import { Component, Input, OnInit } from '@angular/core';
import { PaymentInfo } from 'src/app/core/models/landing/landing';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {
@Input() paymentInfo!: PaymentInfo[];
  constructor() { }

  ngOnInit(): void {
  }

}
