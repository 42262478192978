import { SideMenuService } from 'src/app/core/services/side-menu/side-menu.service';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../logout/logout.component';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss']
})
export class BurgerMenuComponent implements OnInit {
  public menu: boolean = false;
  private path: string = "../assets/svg";
  public visible: boolean = false;
  breakpoint: number = 768;

  constructor(private authService: AuthService,
    private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, public nav: SideMenuService, private dialog: MatDialog) { 
      this.matIconRegistry.addSvgIcon(
        "logo",
        this.setPath(`${this.path}/logo.svg`)
      );
    }
    private setPath(url: string): SafeResourceUrl {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url); 
     }
  ngOnInit(): void {

  }
  showMenu(){
    this.menu = true;
  }
  hideMenu(){
    this.menu = false;
  }
  openDialog() {
    const dialogRef = this.dialog.open(LogoutComponent);
    dialogRef.afterClosed().subscribe(res => {
      window.location.href = ""
    })
  }
  homePage() {
    window.location.href = ""
  }

}
