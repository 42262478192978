import { LoadingScreenService } from '../services/loading/loading-screen.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { finalize, Observable } from "rxjs";



@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {
    
    activeRequests : number = 0;

    constructor( private loadingScreenService: LoadingScreenService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(this.activeRequests === 0) {
           // this.loadingScreenService.showLoading();
        }

        this.activeRequests++;
        return next.handle(request).pipe(
            finalize(() => {
                this.activeRequests--;
                if (this.activeRequests === 0) {
                   // this.loadingScreenService.hideLoading();
                }
            })
        )
    }
}