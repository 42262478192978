<div class="landing_container">
  <div class="logo_container">
    <img src="../../../../assets/img/logo azul.png" alt="logo_confia">
    <div class="title_header">
      <p>Soluciones para tu negocio</p><mat-icon color="accent">arrow_drop_down</mat-icon>
    </div>
    <button mat-flat-button color="primary" routerLink="/dashboard"><mat-icon>account_circle</mat-icon>Inicar sesión</button>
  </div>
  <div class="header_container">
    <h2>Cambia la manera de comprar.</h2>
    <h2>Elige cómo pagar.</h2>
    <p>En Confía creemos en ti y en tus proyectos, por eso queremos ayudarte a que compres lo que necesites y lo pagues como mejor te convenga.</p>
    <button mat-flat-button color="primary" (click)="scrollItem()">Ver métodos de pago</button>
  </div>
  <h3 class="howBuy_title">¿Cómo comprar con Confía?</h3>
  <div class="howBuy_container" >
    <div *ngFor="let item of howToBuy">
      <app-how-to-buy [howToBuy]="item"></app-how-to-buy>
    </div>
  </div>
  <div class="paymentInfo_container">
    <h3 class="howBuy_title">Métodos de pago</h3>
    <p>Disfruta de tus compras sin preocuparte por el pago.</p>
    <p>Diferentes soluciones que se adaptan a cada momento.</p>
    <app-payment-info [paymentInfo]="paymentInfo" id="payInfo"></app-payment-info>
  </div>
  <div class="landingCard_container">
    <div class="landingCard_item" *ngFor="let item of landingCard">
      <app-landing-cards [landingCard]="item"></app-landing-cards>
    </div>
  </div>
</div>




