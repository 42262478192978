<div class="paymentInfo_container">
    <img class="paymentInfo_img" src="../../../../assets/img/pexels-maksim-goncharenok-4352247.png" alt="img_paymentInfo">
    <div class="text_container">
        <div class="paymentInfo_text" *ngFor="let item of paymentInfo">
            <div class="title_container">
                <mat-icon color="accent">check_circle_outline</mat-icon>
                <h3>{{ item.title }}</h3>
            </div>
            <p *ngFor="let item of item.text"> 
                {{item}}
            </p>
        </div>
    </div>
</div>
