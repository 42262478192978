import { Component, Input, OnInit } from '@angular/core';
import { LandingInfo } from 'src/app/core/models/landing/landing';

@Component({
  selector: 'app-how-to-buy',
  templateUrl: './how-to-buy.component.html',
  styleUrls: ['./how-to-buy.component.scss']
})
export class HowToBuyComponent implements OnInit {
@Input() howToBuy!: LandingInfo;
  constructor() { }

  ngOnInit(): void {
  }

}
