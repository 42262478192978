import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  public hiddenConfig:boolean = false
  @Input() isContact: boolean = false;

  faqs = [
/*     {
      question: $localize`:@@faq.question.works:`,
      answer: $localize`:@@faq.answer.works:`,
      hiddenConfig: false
    }, */
    {
      question: '¿Qué es Confia?',
      answer: 'Es un conjunto de soluciones de financiación y pagos adaptadas al comercio físico, al comercio digital y a particulares mediante productos sencillos y transparentes.',
      hiddenConfig: false
    },
    {
      question: '¿Quién esta detrás de Confia?',
      answer: 'Confia es una marca registrada por Unión Financiera Asturiana S.A.E.F.C en funcionamiento desde 1985.',
      hiddenConfig: false
    },
    {
      question: '¿Qué tengo que hacer si me han robado, he perdido o me ha caducado la tarjeta?',
      answer: 'Hacer login con tu cuenta en bnpl.confia.com.es, ir al área de compras y pulsar el icono de añadir método de pago, para cambiar o añadir una nueva tarjeta.',
      hiddenConfig: false
    },
  ]
  toggleConfig(faq: any) {
    this.faqs.forEach((item) => {
      if (item === faq) {
        item.hiddenConfig = !item.hiddenConfig;
      } else {
        item.hiddenConfig = false;
      }
    });
  }
}
