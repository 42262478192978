import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core';

@Component({
  selector: 'app-login.wrapper',
  templateUrl: './login.wrapper.component.html',
  styleUrls: ['./login.wrapper.component.scss']
})
export class LoginWrapperComponent implements OnInit {

  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        const identifier = params['identifier'];
        const email = params['email'];
        const otp = params['otp'];

        this.authService.signIn(identifier, email, otp).subscribe({
          complete: () => {
            this.router.navigate(['/'])
          }
        })
      })
  }

}
