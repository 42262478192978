import { Component, OnInit } from '@angular/core';
import { WebSocketAPI } from 'src/app/core/services/websocket/websocket.api';
import { WebSocketShareService } from 'src/app/core/services/websocket/websocket.service';

@Component({
  selector: 'app-websocket',
  templateUrl: './websocket.component.html',
  styleUrls: ['./websocket.component.scss']
})
export class WebsocketComponent implements OnInit {

  message: string = ''

  constructor( private websocketService: WebSocketShareService,
    private webSocketAPI: WebSocketAPI) { 
      this.connect('test')
      this.onNewValueReceive()
    }

  ngOnInit(): void {
  }

  connect(topic: string) {
    console.log('connect to topic ' + topic)
    this.webSocketAPI.connect(topic);
  }
  disconnect() {
    this.webSocketAPI.disconnect();
  }
  // method to receive the updated data.
  onNewValueReceive() {
    this.websocketService.getNewValue().subscribe(resp => {
      this.message = resp.message
    });
  }
}
