import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { SideMenuService } from './core/services/side-menu/side-menu.service';
import { AuthService } from './core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  public title = 'confia-web';
  public visible: boolean = false;
  public breakpoint: number = 768;
  public burgerMenuVisible!: boolean;
  _login!: boolean;
  public isLanding!: boolean
  constructor( public nav: SideMenuService, private auth: AuthService, private router: Router){ 
    
  }
 

  ngOnInit(): void {
    this.updateVisibility(window.innerWidth);
    this.auth.setTokenLocalStorage();
    this.auth.getTokenLocalStorage().subscribe(
      res =>{
        this._login = res
      }
    )
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.isLanding = event.urlAfterRedirects === '/';
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibility(event.target.innerWidth);
  }

  updateVisibility(width: number) {
    this.burgerMenuVisible = width <= this.breakpoint;
  }
  
};
  

