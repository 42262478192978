<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav
    *ngIf="nav.sideNav"
    class="sideNav_container"
    #sidenav
    [opened]="isVisible"
    [mode]="'side'">
    <div class="action_btn" *ngIf="_isVisibleButton">
      <button mat-icon-button (click)="hideNav()"><mat-icon>menu</mat-icon></button>
    </div>
    <mat-list class="sideNav_list" *ngIf="linkList">
      <div *ngFor="let item of linkList" class="dropdown">
        <div [routerLink]="item.url" routerLinkActive="activeRoute" class="list_btn" (click)="hideNavIfMobile()">
          <mat-icon>{{item.matIcon}}</mat-icon>
          <p>{{item.title}}</p>
        </div>
      </div>
    </mat-list>
  </mat-sidenav>
    <mat-sidenav-content>
      <button *ngIf="!isVisible && isLoggedIn" mat-icon-button (click)="showNav()"><mat-icon>menu</mat-icon></button>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
