
<div class="spinner_container">
    <p class="spinner_title">{{ message }}</p>
    <br>
    <br>
    <mat-spinner  [diameter]="30" style="margin: 0 auto;"></mat-spinner>
    <br>
    <br>  
    <img class="img_logo" src="../../../../assets/png/logo.png" alt="logo confia">
    <br>
    <br>
</div>


    