import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WebsocketMessage } from "../../models/websocket/websocketMessage";

@Injectable()
export class WebSocketShareService implements OnDestroy {
    private wsSubject = new BehaviorSubject<WebsocketMessage>({
        id: -1,
        message: ""
    });

    constructor() { }

    ngOnDestroy(): void {
        this.wsSubject.unsubscribe();
    }
    
    onNewValueReceive(msg: WebsocketMessage) {    
        console.log("onNewValueRecevie")    
        this.wsSubject.next(msg);
    }

    getNewValue(): Observable<WebsocketMessage> {
        return this.wsSubject.asObservable();
    }
}