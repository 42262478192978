import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";
import { User } from "..";
import { MatDialog } from "@angular/material/dialog";
import { LoginComponent } from "src/app/shared/layout/login/login.component";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(readonly auth: AuthService, private router: Router, private dialog: MatDialog) {}

  public prompt(returnUrl: string) {
    this.dialog.open(LoginComponent, { data: {action:'welcome', returnUrl: returnUrl}, disableClose: true })
  }
 
  public disconnect(jumpTo = '/') {
    this.auth.signOut()
    this.router.navigateByUrl(jumpTo);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isAuthenticated()) {
      return true;
    } else {
      this.prompt(state.url);
      return false;
    }
  }

}