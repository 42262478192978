import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class PublicGuard  {
  constructor(private auth: AuthService, private router: Router, private dialog: MatDialog){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!this.auth.isAuthenticated()) {
        return true;
      }else{
        this.router.navigate(['/dashboard'])
        return false
      }
     
  }

  
}
