import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  nav: boolean;
  sideNav: boolean;
  footer: boolean;

  constructor() { 
    this.nav = true;
    this.sideNav = true
    this.footer = true
  }

  hideNav() { this.nav = false; }

  showNav() { this.nav = true; }

  hideSideNav() { this.sideNav = false }

  showSideNav() { this.sideNav = true}

  hideFooter() { this.footer = false }

  showFooter() { this.footer = true }

}
