<div class="profile_container">
  <div class="title_section">
      <mat-icon>help</mat-icon>
  <h2>Preguntas frecuentes</h2>
</div>
<div class="content-faqs-all">
    <div class="content-faqs" *ngFor="let faq of faqs">
      <button class="box-change" (click)="toggleConfig(faq)">
        <mat-icon>{{ faq.hiddenConfig ? 'expand_more' : 'chevron_right' }}</mat-icon>
        <p>{{ faq.question }}</p>
      </button>
      <div class="hidden-faq" *ngIf="faq.hiddenConfig">
        <p>{{ faq.answer }}</p>
      </div>
    </div>
  </div>
  </div>