import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl, FormGroupDirective } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginRequest } from 'src/app/core';
import { AuthService } from '../../../core/services/auth.service';

export type loginAction = 'welcome'|'signIn'|'otp'| 'email';

export interface LoginData {
  action: loginAction,
  returnUrl: string
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

    
  readonly form: FormGroup;

  private primary: FormControl;
  private secondary: FormControl;
  private otp: FormControl;
  
  public error: string | null = null;
  public progress = false;
  public showPassword = false;
  public page: loginAction = 'signIn';

  returnUrl: string = '/'

  regexNumber=/([0-9]{9})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$/;
  regexDNI=/([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/g;

  currentLogin?: LoginRequest | undefined 
  

  constructor(public dialogRef: MatDialogRef<LoginComponent>, @Inject(MAT_DIALOG_DATA) public data: LoginData, private authService: AuthService, private router: Router) {
    this.primary = new FormControl(null, [Validators.required, Validators.pattern(this.regexDNI)]);
    this.secondary = new FormControl(null, [Validators.required, Validators.pattern(this.regexNumber)]);
    this.otp = new FormControl(null, Validators.required);
  
    this.form = new FormGroup({});
    this.returnUrl = data.returnUrl

    this.switchPage(this.page = data.action)
  }

  ngOnInit(): void {
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  private switchPage(page: loginAction) {
    // Resets the status
    this.showPassword = this.progress = false;
    this.error = null;

    // Removes all the controls from the form group
    Object.keys(this.form.controls).forEach( control => {
      this.form.removeControl(control);
    });

    // Add the relevant controls to the form according to selected page
    switch(this.page = page) {

      default:
      case 'welcome':
      case 'email':  
      break;

      case 'signIn':
      this.form.addControl('primary', this.primary);
      this.form.addControl('secondary', this.secondary);      
      break;

      case 'otp':
      this.form.addControl('otp', this.otp);
      break;

    }
  }

  public welcomeAction() {
    this.switchPage('signIn')
  }

  public sendEmailAction() {
    this.progress = true;
    this.sendOtp(this.currentLogin!.identifier, this.currentLogin!.identifier1)
  }

  public activate(action: loginAction, formDirective: FormGroupDirective) {

    this.progress = true;
    
    switch(action) {

      case 'welcome':
      case 'email':
      break;

      case 'signIn':
        this.sendOtp( this.primary.value, this.secondary.value, formDirective);
      break;

      case 'otp':
        this.validateOtp(this.currentLogin!.identifier, this.currentLogin!.identifier1, this.otp.value);
      break;

    }
  }

close() {
  this.dialogRef.close();
}

public sendOtp(primary: string, secondary: string, formDirective?: FormGroupDirective) {
  this.error = null
  this.authService.signIn(primary, secondary).subscribe({
    error: (err) => {
      console.log(err)
      this.progress = false
    //  this.error = 'err.error.message'
      this.error = 'Credenciales inválidas'
    },
    complete: () => {
      this.currentLogin = {
        identifier: primary,
        identifier1: secondary
      }
      this.progress = false
      formDirective?.resetForm()
      if (this.isEmail(secondary)) {
        this.switchPage('email')
      } else {
        this.switchPage('otp')
      }
    }
  })
}

public validateOtp(primary: string, secondary: string, otp: string) {
  this.error = null
  this.authService.signIn(primary, secondary, otp).subscribe({
    error: (err) => {
      console.log(err)
      this.progress = false
    //  this.error = 'err.error.message'
      this.error = 'Otp inválida'
    },
    complete: () => {
      this.currentLogin = undefined
      this.router.navigateByUrl(this.returnUrl)
      this.close();
    }
  })
}

private isEmail(value: string): boolean {
  var re = /\S+@\S+\.\S+/;
  return re.test(value);
}

}
