import { LandingInfo } from './../../../core/models/landing/landing';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-cards',
  templateUrl: './landing-cards.component.html',
  styleUrls: ['./landing-cards.component.scss']
})
export class LandingCardsComponent implements OnInit {
@Input() landingCard!: LandingInfo;
  constructor() { }

  ngOnInit(): void {
  }

}
