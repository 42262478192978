<ng-container [formGroup]="addressForm">
  <mat-card class="shipping-card">
    <mat-card-header>
      <mat-card-title>Información de envío</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <textarea matInput placeholder="Dirección" formControlName="address"></textarea>
            <mat-error *ngIf="addressForm.controls['address'].hasError('required')">
              La dirección es <strong>obligatoria</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="!hasUnitNumber">
        <div class="col">
          <button mat-button type="button" (click)="hasUnitNumber = !hasUnitNumber">
            + Añadir más info
          </button>
        </div>
      </div>
      <div class="row" *ngIf="hasUnitNumber">
        <div class="col">
          <mat-form-field class="full-width">
            <textarea matInput placeholder="Más info" formControlName="address2"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Comunidad Autónoma" formControlName="state" (selectionChange)="showCounties($event)">
              <mat-option *ngFor="let state of states" [value]="state.id">
                {{ state.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="addressForm.controls['state'].hasError('required')">
              La comunidad autónoma es <strong>obligatoria</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Provincia" formControlName="county">
              <mat-option *ngFor="let province of counties" [value]="province.code">
                {{ province.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="addressForm.controls['county'].hasError('required')">
              La provincia es <strong>obligatoria</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Municipio" formControlName="city">
            <mat-error *ngIf="addressForm.controls['city'].hasError('required')">
              El municipio es <strong>obligatorio</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Código postal" formControlName="postalCode">
            <mat-error *ngIf="addressForm.controls['postalCode'].hasError('required')">
              Los apellidos son <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
