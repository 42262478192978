import { PaymentInfo, LandingInfo } from './../../../core/models/landing/landing';
import { SideMenuService } from './../../../core/services/side-menu/side-menu.service';
import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  public howToBuy!: LandingInfo[];
  public paymentInfo!: PaymentInfo[];
  public landingCard!: LandingInfo[];
  @Input() hiddenComponent: boolean = true;
  constructor( public nav:SideMenuService) {
    this.nav.hideSideNav();
    this.nav.hideNav();
    

    this.howToBuy = [
      {
        icon:{
          src:"../../../assets/png/Shop.png",
          alt:"shop_icon"
        },
        title:"Compra en la tienda",
        text:"y dirígete al pago."
      },
      {
        icon:{
          src:"../../../assets/png/logoicon.png",
          alt:"confia_logo"
        },
        title:"Selecciona Confia",
        text:"como método de pago."
      },
      {
        icon:{
          src:"../../../assets/png/Id.png",
          alt:"id_icon"
        },
        title:"Formulario mínimo",
        text:"Sólo con tu Dni y móvil."
      },
      {
        icon:{
          src:"../../../assets/png/Check.png",
          alt:"check_icon"
        },
        title:"¡Hecho!",
        text:"Respuesta inmediata."
      },
    ],

    this.paymentInfo = [
      {
        title:"Paga después",
        text:[
          "Paga tu compra una vez la tengas en tus manos.",
          "Comprueba tu pedido antes de pagar y evita dolores de cabeza con los reembolsos de tus devoluciones. Paga tu compra solo si te la quedas desde tu cuenta de usuario. El pago se realiza con la tarjeta, transferencia bancaria o ingreso en cuenta (recibirás los detalles por email)."
      ]
      },
      {
        title:"Divide en 3",
        text:[
          "No te lo gastes todo de golpe.",
          "Dale un respiro a tu bolsillo y reparte el pago en 3 partes. La solución perfecta para esas compras que hacen que tu cuenta del banco ese mes vaya un poco más justa que de costumbre."
        ]
      },
      {
        title:"Pago fraccionado",
        text:[
          "No te lo gastes todo de golpe.",
          "Dale un respiro a tu bolsillo y reparte el pago en 3 partes. La solución perfecta para esas compras que hacen que tu cuenta del banco ese mes vaya un poco más justa que de costumbre."
        ]
      },
      {
        title:"Pago aplazado",
        text:[
          "Un respiro cuando llegan los meses de gastos.",
          "Navidad, vuelta al cole, vacaciones... Hay meses que los gastos se triplican. Con esta solución puedes comprar lo que necesites este mes y pagarlo más adelante."
        ]
      },
    ],
    this.landingCard = [
      {
        icon:{
          src:"../../../assets/png/noun-lock-locked-4020631.png",
          alt:"lock_icon"
        },
        title:"Tus datos en un solo lugar.",
        text:"No compartimos tus datos financieros con los comercios. Podrás guardar tus tarjetas de forma segura y utilizaras en la tienda que elijas sin tener que añadirla de nuevo en cada compra."
      },
      {
        icon:{
          src:"../../../assets/png/noun-client-4201396.png",
          alt:"card_icon"
        },
        title:"Protección del comprador incluida.",
        text:"Todas las compras con Confía están cubiertas con la Protección del Comprador. Si no estás satisfecho con el artículo que has recibido, mediamos con la tienda para ayudarte a obtener el reembolso"
      },
    ]
  }

  ngOnInit(): void {
    
  }
  hideNav(){
    this.hiddenComponent = false
  }
  scrollItem() {
    let el = document.getElementById('payInfo');
    el?.scrollIntoView({behavior:"smooth"});
  }
  
}
