import { Injectable, OnDestroy } from "@angular/core";
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from "rxjs";
import { Observable, Subscription, tap } from "rxjs";
import { LoginResponse, User } from "../models";
import { LoginService } from "./login/login.service";

@Injectable()
export class AuthService implements OnDestroy {
  token$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private logout: Subscription = new Subscription;

  constructor(public jwtHelper: JwtHelperService, private loginService: LoginService) {
  }


  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token!);
  }

  public signIn(primaryKey: string, secondaryKey: string, otp?: string): Observable<LoginResponse> {
    const req = {
      identifier: primaryKey, 
      identifier1: secondaryKey,
      otp: otp
    };

    return this.loginService.login(req)
      .pipe(
        tap(res => {
          if (res.token != null) {
            localStorage.setItem('token', res.token!)
            this.setTokenLocalStorage()
          }
        })
      )
  }
  getTokenLocalStorage(): Observable<boolean> { 
    return this.token$.asObservable();
  }

  setTokenLocalStorage() {
    this.token$.next(this.checkToken());
    console.log('seteado', this.getTokenLocalStorage())
  }
  checkToken() { 
    if(localStorage.getItem('token')) {
      return true
    } else {
      return false
    }
  }

  public signOut() {
    localStorage.removeItem('token')
    this.setTokenLocalStorage();
  }

  public token() {
    return localStorage.getItem('token')
  }

  ngOnDestroy() { 
    this.logout.unsubscribe();
  }
}