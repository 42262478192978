import { BurgerMenuComponent } from './layout/burger-menu/burger-menu.component';
import { LogoutComponent } from './layout/logout/logout.component';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';

import { MaterialModule } from './material.module';
import { HeaderComponent, FooterComponent, SideMenuComponent, LoginComponent } from './layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EncrDecrService } from './utils/encr-decr-service.service';
import { LoginWrapperComponent } from './layout/login/login.wrapper.component';
import { AddressComponent } from './layout/address-form/address.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatDialogRef } from '@angular/material/dialog';
import { WebsocketComponent } from './layout/websocket/websocket.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent, 
    SideMenuComponent, 
    LoginComponent, 
    SpinnerComponent,
    LogoutComponent,
    BurgerMenuComponent,
    AddressComponent,
    WebsocketComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule
  ],
  exports: [

    MaterialModule,
    HeaderComponent, 
    FooterComponent, 
    SideMenuComponent, 
    LoginComponent, 
    SpinnerComponent,
    LogoutComponent,
    BurgerMenuComponent,
    AddressComponent,
    RouterModule,
  ],
  providers: [
    EncrDecrService
  ]
})
export class SharedModule { }
