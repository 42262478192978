import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { ErrorInterceptor, JwtInterceptor, LoadingScreenInterceptor } from './interceptors';
import { AuthGuard, AuthService, LoginMockService, LoginService } from './services';
import { WebSocketAPI } from './services/websocket/websocket.api';
import { WebSocketShareService } from './services/websocket/websocket.service';
import { PublicGuard } from './services/public.guard';

@NgModule({
  declarations: [],
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          console.log('tokenGetter called = ' + localStorage.getItem('authToken'));
          return localStorage.getItem('authToken');
        }
      }
    })
  ],
  providers: [
    AuthService,
    LoginService,
    LoginMockService,
    JwtHelperService,
    AuthGuard,
    PublicGuard,
    WebSocketAPI,
    WebSocketShareService,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    /* { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true } */
  ]
})

export class CoreModule { 
  constructor(@Optional() @SkipSelf() core: CoreModule ){
    if (core) {
        throw new Error("You should import core module only in the root module")
    }
  }
}
